const link = ({ url, text, origin = 'https://compareclub.com.au/life-insurance' } = {}) => {
  if (!text) {
    return ''
  }

  const path = `${origin}/${url || ''}`

  return `
    <a
      class="underline font-bold inline-block"
      target="_blank"
      href="${path}"
      rel="noopener"
    >
      ${text}
    </a>
  `
}

export const GREAT_DEAL_ITEMS = [
  {
    title: 'Life Cover',
    description: 'Life insurance can help<br class="hidden lg:inline" /> protect your loved ones\'<br class="hidden lg:inline" /> financial future with<br class="hidden lg:inline" /> a lump sum payout if you<br class="hidden lg:inline" /> pass away or are diagnosed<br class="hidden lg:inline" /> with a terminal illness.',
    icon: `${APP_ASSET_BASEURL}edge/static/images/products/life/life-cover.svg`,
    iconDimensions: {
      width: 64,
      height: 64
    },
    url: '/life-insurance/what-is-life-insurance/'
  },
  {
    title: 'Income Protection Insurance',
    description: 'Income protection insurance<br class="hidden lg:inline" /> can pay up to 70% of your<br class="hidden lg:inline" /> salary if you are unable to<br class="hidden lg:inline" /> work due to injury or illness.',
    icon: `${APP_ASSET_BASEURL}edge/static/images/products/life/income-protection-insurance.svg`,
    iconDimensions: {
      width: 64,
      height: 64
    },
    url: '/life-insurance/income-protection-insurance/'
  },
  {
    title: 'Total & Permanent Disablement Insurance',
    description: 'Claim a lump sum to help<br class="hidden lg:inline" /> with your finances if you<br class="hidden lg:inline" /> become permanently unable to work again due<br class="hidden lg:inline" /> to an illness or injury.',
    icon: `${APP_ASSET_BASEURL}edge/static/images/products/life/total-permanent-disablement-insurance.svg`,
    iconDimensions: {
      width: 64,
      height: 64
    },
    url: '/life-insurance/tpd-insurance/'
  },
  {
    title: 'Trauma (Critical Illness) Insurance',
    description: 'Claim a lump sum to help<br class="hidden lg:inline" /> with your finances if you\'re<br class="hidden lg:inline" /> diagnosed with a critical<br class="hidden lg:inline" /> illness that is included in the insurer policy.',
    icon: `${APP_ASSET_BASEURL}edge/static/images/products/life/trauma-insurance.svg`,
    iconDimensions: {
      width: 64,
      height: 64
    },
    url: '/life-insurance/trauma-insurance/'
  }
]

export const SAVING_PLAN_ITEMS = [
  {
    title: 'Get peace of mind',
    description: 'You and your family shouldn\'t have the added worry of financial stress if you get seriously ill, injured or worse. When you choose to buy life insurance, you get peace of mind that your loved ones will get financial support, in the event you pass away or become terminally ill.',
    sectionImage: `${APP_ASSET_BASEURL}edge/static/images/products/life/peace-of-mind.svg`,
    sectionImageDimensions: {
      width: 103.28,
      height: 80
    }
  },
  {
    title: 'Covers debts and major expenses',
    description: 'What would happen to your family if your wage disappeared? Life insurance is designed to provide you and your family with a financial safety net to help pay for your living expenses.',
    sectionImage: `${APP_ASSET_BASEURL}edge/static/images/products/life/debts-major-expenses-cover.svg`,
    sectionImageDimensions: {
      width: 89.33,
      height: 80
    }
  },
  {
    title: 'Makes up for lost income',
    description: 'Life insurance can help cover everyday expenses if you can\'t work because of illness or injury, even if it\'s just temporary. We’ll help you compare life insurance quotes so you can find a policy that keeps your bank balance healthy while you\'re recovering.',
    sectionImage: `${APP_ASSET_BASEURL}edge/static/images/products/life/lost-income.svg`,
    sectionImageDimensions: {
      width: 107.85,
      height: 80
    }
  },
  {
    title: 'Medical expenses',
    description: 'Recovering from a serious medical condition is stressful enough without worrying about medical bills. A life policy payout can help get you back on your feet by covering the cost of major medical expenses.',
    sectionImage: `${APP_ASSET_BASEURL}edge/static/images/products/life/medical-expenses.svg`,
    sectionImageDimensions: {
      width: 94.9,
      height: 80
    }
  }
]

export const GUIDES_ITEMS = [
  {
    title: 'Find affordable life cover for over 50s',
    content: 'Turning 50 shouldn\'t mean good life cover is beyond your budget. Here\'s how older Australians can find affordable life policies.',
    imageUrl: `${APP_ASSET_BASEURL}edge/static/images/products/life/cover-over-50.png`,
    pageUrl: '/life-insurance/over-50s/',
    imageDimensions: {
      width: 291,
      height: 202
    }
  },
  {
    title: 'Life insurance for smokers',
    content: 'Smokers can still get life insurance. Find out what it\'ll cost and how quitting can cut down your premiums.',
    imageUrl: `${APP_ASSET_BASEURL}edge/static/images/products/life/smokers.png`,
    pageUrl: '/life-insurance/smokers/',
    imageDimensions: {
      width: 291,
      height: 202
    }
  },
  {
    title: 'Life insurance and pre-existing conditions',
    content: 'Many insurers will still cover Australians even if they have pre-existing conditions. Here\'s what you need to know.',
    imageUrl: `${APP_ASSET_BASEURL}edge/static/images/products/life/pre-existing-conditions.png`,
    pageUrl: '/life-insurance/pre-existing-conditions/',
    imageDimensions: {
      width: 291,
      height: 202
    }
  }
]

export const FAQ_ITEMS = [
  {
    title: 'When’s the best time to take out life cover?',
    content: `
      <p>
        Most people tend to get life insurance when they get their first mortgage, get married or have a baby or when other life significant events occur.
      </p>
    `
  },
  {
    title: 'How is my life cover premium calculated?',
    content: `
      <p>
        Your life insurance contract is based on your circumstances when your policy starts, so if your health changes AFTER that, your policy is not affected.
      </p>
    `
  },
  {
    title: 'Can my job affect my life cover premium?',
    content: `
      <p>
        Yes it can. If you change occupation to a lower risk job you may be able to apply for lower rates for Total and Permanent Disability (TPD) and Income Protection cover.
      </p>
    `
  },
  {
    title: 'What is the difference between stepped and level premiums?',
    content: `
      <p class="mb-4">
        Life insurance premiums can be either stepped or level premiums. A simple way of thinking about it is that stepped premiums go up as you get older, while level premiums are not fixed to your age.
      </p>

      <p class="mb-4">
        The ${link({ url: 'cost', text: 'price of your premiums' })} - whether quoted outside of a policy or within an active policy, the price of your premiums tends to increase with age as you get older. It makes sense because the older you are, the more likely you are to make a claim.
      </p>

      <p class="mb-4">
        Stepped premiums usually start lower than level premiums but will ${link({ url: 'over-65', text: 'increase as you get older' })}. Depending on other factors, such as your policy type, your annual life insurance premium could even increase by as much as hundreds of dollars in just the first few years.
      </p>

      <p class="mb-4">
        Level premiums are a little more stable as they are not linked to your age. They will be a bit higher than stepped premiums at the start, but any rises are not linked to your age – your costs will only rise if your insurer puts the premiums up and your premiums rise with inflation.
      </p>

      <p>
        Some insurers also offer hybrid premiums, which is a combination of level and stepped premiums. Hybrid premiums are initially more expensive than stepped premiums, but are generally cheaper than level premiums. Hybrid premiums increase over a set period of time and then lock into a premium once you turn a certain age.
      </p>
    `
  }
]

export const PARTNER_ITEMS = [
  {
    company: 'aia.svg',
    dimensions: {
      width: 63.99,
      height: 69.99
    }
  },
  {
    company: 'one-path.svg',
    dimensions: {
      width: 96,
      height: 32.99
    }
  },
  {
    company: 'tal.svg',
    dimensions: {
      width: 96,
      height: 38.99
    }
  },
  {
    company: 'mlc.svg',
    dimensions: {
      width: 96,
      height: 62.99
    }
  },
  {
    company: 'clear-view.svg',
    dimensions: {
      width: 102,
      height: 19.99
    }
  },
  {
    company: 'zurich.svg',
    dimensions: {
      width: 102,
      height: 24.99
    }
  }
]

export const SITE_LINKS = [
  {
    title: 'Life Insurance For Seniors',
    url: 'https://compareclub.com.au/life-insurance/over-65/',
    desc: 'Most insurers offer seniors life insurance up to a certain age known as the \'maximum entry age\'. The cut-off age for getting a new life insurance policy usually ranges from 60 to 75, depending on the policy and the insurer.'
  },
  {
    title: 'Life Insurance For Families',
    url: 'https://compareclub.com.au/life-insurance/families/',
    desc: 'Life insurance can help ease the financial strain on the family if one parent were to be injured, have a serious illness or even pass away.'
  },
  {
    title: 'Life Insurance For Smokers',
    url: 'https://compareclub.com.au/life-insurance/smokers/',
    desc: 'Smokers can get life insurance from all insurers that Compare Club works with. Average premiums for smokers are usually higher as health and lifestyle habits are assessed by insurers.'
  },
  {
    title: 'Trauma Cover',
    url: 'https://compareclub.com.au/life-insurance/trauma-insurance/',
    desc: 'Also known as \'critical illness cover\', this type of cover provides a lump sum of money in the event of a particular illness or injury, that is included as a defined condition in the policy.'
  },
  {
    title: 'Income Protection',
    url: 'https://compareclub.com.au/life-insurance/income-protection-insurance/',
    desc: 'This type of cover pays up to 70% of your usual income, usually monthly, if you can\'t work temporarily as a result of illness or injury.'
  },
  {
    title: 'TPD Cover',
    url: 'https://compareclub.com.au/life-insurance/tpd-insurance/',
    desc: 'A type of life insurance that pays a lump sum when the policy holder becomes totally and permanently disabled due to an illness or injury and is unable to work ever again.'
  }
]

export const GLOSSSARY_LIST = [
  {
    title: 'B-H',
    content: `
      <p class="mb-4">
        <strong>Beneficiary:</strong> The person or entity you choose to receive any of your payable benefits, in the event a covered incident occurs. Note: in a case of critical illness or total and permanent disability, the beneficiary is usually you but you can nominate someone else. In the case of your death, any benefit payment will go to your chosen person, or organisation. Key points about beneficiaries include:
      </p>
      <p class="mb-4">
        - Designation: You can choose your own beneficiary. It can be your spouse, child, family member, friend, trust, or even a charitable organisation. You’re able to select multiple beneficiaries and specify the percentage or amount each should receive.
      </p>
      <p class="mb-4">
        - Primary and Contingent Beneficiaries: Your primary beneficiary is the first person or entity entitled to receive the death benefit. If your primary beneficiary predeceases you, or is unable to receive the benefit, your contingent beneficiary, also known as yout secondary beneficiary, will receive your benefit. Contingent beneficiaries are often named as backups to ensure your death benefit goes to your intended recipients.
      </p>
      <p class="mb-4">
        - Change of Beneficiary: Typically, you have the right to change your beneficiary/ies at any stage of your life cover. It's important to keep this information up to date, reflecting any changes in circumstances, such as marriage, divorce, or the birth of your children.
      </p>
      <p class="mb-4">
        - Irrevocable Beneficiary: In some cases, you may choose to designate your beneficiary as "irrevocable." This means that you can’t change your beneficiary without their written consent. It's important to carefully consider the implications of an irrevocable beneficiary designation, as it limits your flexibility.
      </p>
      <p class="mb-4">
        - Your Estate as Beneficiary: If you name your estate as your beneficiary, your death benefit becomes part of your estate and can be subject to estate taxes and creditors' claims. Naming specific individuals or entities as beneficiaries can avoid these complications.        
      </p>

      <p class="mb-4">
        <strong>Charitable Giving:</strong> Life insurance can also be used to create a legacy by designating a charitable organization as a beneficiary. This allows individuals to support causes or organizations that are important to them, leaving a lasting impact beyond their lifetime.
      </p>

      <p class="mb-4">
        <strong>Death Benefit:</strong> The amount of money paid by your insurance company to your beneficiary/ies upon your death. It’s typically a tax-free lump sum.
      </p>

      <p class="mb-4">
        <strong>Death Cover:</strong> Death cover, also known as death benefit or life cover, is the fundamental component of a life insurance policy. It pays out a lump sum to your designated beneficiaries upon your death. Your death benefit is typically paid out to help your beneficiaries cover expenses such as funeral costs, outstanding debts, medical costs, mortgage payments, education expenses, and day-to-day living expenses.
      </p>

      <p class="mb-4">
        <strong>Exclusion:</strong> Specific circumstances, conditions, or events that aren’t covered by your insurance policy. Exclusions vary between policies, and it's essential to review them carefully before purchasing a policy.
      </p>      
    `
  },
  {
    title: 'I-R',
    content: `
      <p class="mb-4">
        <strong>Income Protection Insurance:</strong> Also known as salary continuance insurance, income protection provides a replacement income if you’re unable to work due to illness or injury. It usually pays out around 70% of your regular income for a specified period.
      </p>

      <p class="mb-4">
        <strong>Legatee:</strong> A legatee refers to an individual or entity named in your Will or trust to receive a specific bequest or gift. In the context of life cover, your legatee can also be your payout beneficiary.
      </p>

      <p class="mb-4">
        Key points about legatees include:
      </p>
      
      <ol class="mb-4">
        <li class="mb-4">a. Bequest Designation: A legatee is typically designated in a legal document such as a will or trust. The insured person, as the policyholder, has the discretion to name one or more legatees as beneficiaries of their life insurance policy.</li>
        <li class="mb-4">b. Specific Bequest: Unlike primary and contingent beneficiaries, who may receive a share of the overall death benefit, a legatee is typically named to receive a specific bequest or gift. This could be a fixed amount of money, a particular asset, or a percentage of the estate.</li>
        <li class="mb-4">c. Importance of Proper Documentation: To ensure that the life insurance proceeds are distributed according to the insured person's wishes, it is crucial to clearly and accurately designate legatees in the appropriate legal documents. This helps avoid confusion or disputes regarding the distribution of assets.</li>
        <li>d. Relationship to Estate Planning: Legatees play a significant role in estate planning and the distribution of assets after an individual's passing. They may be family members, friends, charitable organizations, or any other entity the insured person wishes to benefit.</li>
      </ol>

      <p class="mb-4">
        <strong>Life Insurance, also known as Death Cover:</strong> A contract between you and your insurance company, where the insurer agrees to pay out a specific amount of money in the event of your death (i.e., your death benefit), to your beneficiaries when you die.
      </p>

      <p class="mb-4">
        <strong>Policyholder:</strong> The individual who purchased and owns the life insurance policy (i.e. you). You may also be referred to as ‘the insured’.
      </p>

      <p class="mb-4">
        <strong>Premium:</strong> The amount of money you pay to your insurance company to maintain your cover. Premiums can be paid monthly, quarterly, annually, or in some other agreed-upon frequency.
      </p>
    `
  },
  {
    title: 'S-W',
    content: `
      <p class="mb-4">
        <strong>Total and Permanent Disability (TPD) Cover:</strong> TPD cover pays a lump sum benefit if you become totally and permanently disabled and can’t work ever again in your own or any occupation for which you’re reasonably suited based on education, training, and/or experience.
      </p>

      <p class="mb-4">
        <strong>Trauma Cover:</strong> Also referred to as critical illness insurance, trauma cover provides a lump sum payment if you’re diagnosed with a specified critical illness, or you experience a major medical event. Examples of covered conditions include cancer, heart attack, stroke, or a major organ transplant.
      </p>

      <p class="mb-4">
        <strong>Underwriting:</strong> This is the process where your insurance company evaluates your risk profile, including your health, occupation, lifestyle, and medical history. This is done to determine whether you will be offered cover, and the price of your insurance premium. <strong class="italic">*please note: that conditions may apply depending on your policy</strong>
      </p>

      <p class="mb-4">
        <strong>Waiting Period:</strong> In life insurance, the waiting period refers to the specified period of time that must elapse after the policy's inception or a claimable event before the policyholder becomes eligible to receive certain benefits.
      </p>
      
      <p class="mb-4">Key points about waiting periods in life insurance include:</p>
      
      <ol class="mb-4">
        <li class="mb-4">
          a. Duration: The waiting period can vary depending on the terms and conditions of the life insurance policy. It is typically defined in terms of days, weeks, or months. Common waiting period durations range from 30 days to six months, but they can be longer or shorter depending on the specific policy.
        </li>
        <li class="mb-4">
          b. Claimable Events: The waiting period often applies to specific events or conditions. For example, in the case of a life insurance policy, the waiting period might be triggered by a claim for certain critical illnesses or total and permanent disability benefits. During the waiting period, the policyholder is not eligible to receive these particular benefits even if the triggering event occurs.
        </li>
        <li class="mb-4">
          c. Exclusions: It's important to review the policy's terms and conditions to understand any exclusions or limitations.. Certain events or conditions may be explicitly excluded from coverage, or there may be specific waiting periods for different benefits within the same policy.
        </li>
        <li class="mb-4">
          d. Premium Payments: It's worth noting that premium payments are typically required during the waiting period, even though the policyholder may not be eligible for certain benefits during that time. Regular premium payments ensure that the policy remains active and in force until the term expires or for as long as you choose to keep it.
        </li>
      </ol>

      <p class="mb-4">
        <strong>Waiting Period:</strong> The initial period after your illness or injury occurs, during which you must be unable to work before you’re entitled to any benefit payments under an income protection policy. This can be an important factor when selecting a policy, and an insurer.
      </p>

      <p class="mb-4">
        <strong>Wealth Preservation:</strong> Life insurance can be used as a tool for preserving wealth and passing it on to future generations. By providing liquidity to cover estate settlement costs, such as taxes or legal fees, life insurance can help ensure that the intended beneficiaries receive the full value of the estate.
      </p>
    `
  }
]

export const COMPARISON_TABLE_DATA = {
  '$1 Million Cover': {
    '45 Years Old': {
      'Male - Daily Premium': '$1.32',
      'Male - Monthly Premium': '$40.35',
      'Female - Daily Premium': '$1.08',
      'Female - Monthly Premium': '$32.99'
    },
    '50 Years Old': {
      'Male - Daily Premium': '$2.35',
      'Male - Monthly Premium': '$71.45',
      'Female - Daily Premium': '$1.61',
      'Female - Monthly Premium': '$49.10'
    },
    '55 Years Old': {
      'Male - Daily Premium': '$5.39',
      'Male - Monthly Premium': '$162.80',
      'Female - Daily Premium': '$3.49',
      'Female - Monthly Premium': '$106.31'
    },
    '60 Years Old': {
      'Male - Daily Premium': '$11.83',
      'Male - Monthly Premium': '$359.97',
      'Female - Daily Premium': '$7.67',
      'Female - Monthly Premium': '$233.33'
    },
    '65 Years Old': {
      'Male - Daily Premium': '$23.23',
      'Male - Monthly Premium': '$706.71',
      'Female - Daily Premium': '$13.60',
      'Female - Monthly Premium': '$413.77'
    }
  },
  '$2 Million Cover': {
    '45 Years Old': {
      'Male - Daily Premium': '$2.54',
      'Male - Monthly Premium': '$77.13',
      'Female - Daily Premium': '$2.04',
      'Female - Monthly Premium': '$63.07'
    },
    '50 Years Old': {
      'Male - Daily Premium': '$4.49',
      'Male - Monthly Premium': '$136.60',
      'Female - Daily Premium': '$4.49',
      'Female - Monthly Premium': '$93.86'
    },
    '55 Years Old': {
      'Male - Daily Premium': '$10.09',
      'Male - Monthly Premium': '$307.02',
      'Female - Daily Premium': '$6.68',
      'Female - Monthly Premium': '$203.22'
    },
    '60 Years Old': {
      'Male - Daily Premium': '$22.04',
      'Male - Monthly Premium': '$670.47',
      'Female - Daily Premium': '$22.04',
      'Female - Monthly Premium': '$415.97'
    },
    '65 Years Old': {
      'Male - Daily Premium': '$42.28',
      'Male - Monthly Premium': '$1,316.32',
      'Female - Daily Premium': '$25.33',
      'Female - Monthly Premium': '$770.68'
    }
  }
}

export const WHY_GET_LIFE_INSURANCE = {
  'Retail life insurance': {
    'Ownership Structure': 'Different ownership structures available to individuals including: <br class="hidden lg:block" />Self ownership, Joint ownership, Cross ownership',
    'Policy Options': 'Retail life insurance policies can be customized with additional options such as accidental death insurance & child cover',
    'Cover amount': 'Cover can be tailored to your particular requirements',
    Premiums: 'Different premium types are available for a retail life insurance policy such as: Stepped, Level, Hybrid'
  },
  'Default life in super': {
    'Ownership Structure': 'Typically the owner of your policy will be the super fund or organisation that is funding your life',
    'Policy Options': 'Default life in super policies typically don’t allow customization. Your life cover will not be tailored to your particular requirements.',
    'Cover amount': 'Default life in super policies typically have comparatively low cover amounts compared to retail life insurance policies.',
    Premiums: 'Typically cheaper premiums than retail life insurance however you are limited in how you can tailor your cover. Premiums are directly taken from your superannuation account.'
  }
}
